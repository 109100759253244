
import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Hero from "../components/hero"
import ContactSection from "../components/contact-section"
import OrganizationsRow from "../components/organizations-row"
import './organizations.scss'
import { useViewport } from '../hooks/useViewPort'




function OrganizationsPage() {

  const data = useStaticQuery(
    graphql`
      query {
        organizations: allWordpressWpKoeCompany(sort: {fields: acf___koe_company_logo___alt_text, order: ASC}) {
          edges {
            node {
              title
              wordpress_id
              acf {
                koe_company_logo {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
                koe_teaser_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
                koe_teaser_text
                koe_website
              }
            }
          }
        }
        organizationsBannerDesktop: wordpressWpKoeCompany(
          title: {eq: "Endeavour"}
        ) {
          acf {
            koe_header_text
            koe_header_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
          title
        }
        organizationsBannerMobile:wordpressWpKoeCompany(title: {eq: "Endeavour"}) {
          acf {
            koe_header_text
            koe_header_image_mobile {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    `
  )

  const sources = [
    data.organizationsBannerMobile.acf.koe_header_image_mobile.localFile.childImageSharp.gatsbyImageData,
    {
      ...data.organizationsBannerDesktop.acf.koe_header_image.localFile.childImageSharp.gatsbyImageData,
      media: `(min-width: 426px)`,
    },
  ]

  const organizations = data.organizations.edges
  const rows = organizations.reduce(function (rows, key, index) { 
      return (index % 2 === 0 ? rows.push([key]) 
        : rows[rows.length-1].push(key)) && rows;
    }, []);

  const { width } = useViewport();
  const breakpointMobile = width < 426;



  return (
    <>
      <Layout>
        <Seo title="Organizations" />
        <Hero image={sources} alt={"Organizations Banner"}/>
        <section className='outer-organizations-specifics-container'>
          <div className='inner-specifics-container'>
            <div className='organizations-wrapper'>
              {
                OrganizationsRow(rows)
              }
            </div>
          </div>
        </section>
        {
          breakpointMobile ?
            <ContactSection />
            :
            null
        }
      </Layout>
    </>
  )
}



export default OrganizationsPage
