import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ArrowLink from '../images/icons/arrow-link.svg'
import './organization-container.scss'
import Plx from 'react-plx';

const OrganizationContainer = ({ organization, index }) => {


    const slideInFirstOrganization = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: -500,
                    endValue: 0,
                    property: 'translateX',
                },
            ],
        },
    ];

    const slideInSecondOrganization = [
        {
            start: "self",
            end: "self",
            endOffset: "50vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: -1000,
                    endValue: 0,
                    property: 'translateX',
                },
            ],
        },
    ];


    return (
        <Plx
            parallaxData={index % 2 !== 0 ? slideInFirstOrganization : slideInSecondOrganization}
            className="plx-container"
        >
            <div className='single-organization-wrapper'>
                <div className='organization-container'>
                    <div className='text-container'>
                        <h3>{organization.node.title}</h3>
                        <p>{organization.node.acf.koe_teaser_text}</p>
                        <div className='cta-container'>
                            <a href={organization.node.acf.koe_website}>Bezoek de website <ArrowLink /></a>
                        </div>
                    </div>
                    <div className='mini-banner-container'>
                        <GatsbyImage image={organization.node.acf.koe_company_logo.localFile.childImageSharp.gatsbyImageData} alt={organization.node.acf.koe_teaser_text} />
                    </div>
                    <div className='organization-img-container'>
                        <div className='organization-img-inner-container'>
                            <GatsbyImage image={organization.node.acf.koe_teaser_image.localFile.childImageSharp.gatsbyImageData} alt={organization.node.acf.koe_teaser_text} />
                        </div>
                    </div>
                </div>
            </div>
        </Plx>
    )
}

export default OrganizationContainer


