import * as React from "react"
import OrganizationContainer from "./organization-container"
import './organizations-row.scss'


const OrganizationsRow = (rows) => {
    return (
        <>
        {
            rows.map((row, index) => (
                <div className="organizations-row" key={index}>
                    {
                        row.map((organization, index) => (                             
                            <OrganizationContainer key={index} organization={organization} index={index} />
                        ))
                    }
                </div>
            ))
        }
        </>
    )
}

export default OrganizationsRow;